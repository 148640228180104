.story {
    background-color: #ffffff;
    padding: 5rem 1rem;
    color: rgb(0, 0, 0);
  }
  
  .storyContainer {
    max-width: 100%;
    margin: 0 auto;
  }
  
  .storyTitle {
    font-size: 3.75rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .storyIntro {
    text-align: center;
    max-width: 80%;
    font-size: 2rem;
    font-weight: 500;
    margin: 0 auto 4rem;
    line-height: 1.6;
  }
  
  .storyContent {
    display: center;
    flex-direction: column;
    max-width: 60%;
    margin: 0 auto;
    font-size: 1.25rem;
    gap: 4rem;
  }
  
  .storyBlock {
    display: flex;
    align-items: top;
    gap: 3rem;
  }
  
  .storyBlock.reverse {
    flex-direction: row-reverse;
  }
  
  .storyImage {
    width: 200px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 1rem;
    margin-bottom: 2rem;
    background-color: #e5e7eb;
    
    object-fit: cover;
  }
  .storyImage img{
    width: 100%;
    height: 100%;

  }
  
  .storyText {
    flex: 1;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .steps {
      flex-direction: column;
      gap: 1rem;
    }
  
    .stepLine {
      width: 1px;
      height: 20px;
    }
  
    .storyBlock, 
    .storyBlock.reverse {
      flex-direction: column;
      text-align: center;
    }
  
    .storyImage {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
    }
  }