.getStarted {
    padding: 5rem 1rem;
    text-align: center;
  }
  
  .getStartedTitle {
    font-size: 2.75rem;
    font-weight: 700;
    color: black;
    margin-bottom: 3rem;
  }
  
  .steps {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .step {
    font-size: 1.25rem;
    font-weight: 500;
  }
  
  .step:first-child {
    text-decoration: underline;
  }
  
  .stepLine {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
  }