html, body {
    overflow: auto;
    background-color: #F0F0F5;
}



.container {
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    background-color: #F0F0F5;
    font-family: Arial, sans-serif;
    min-height: calc(100vh - 47px);
}

.container_e {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    margin-top: 3vh;
    margin-bottom: 5vh;
}

.settings_title {
    font-size: 24px;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
    color: #1B263B;
}

.profile_picture_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
}

.profile_picture {
    border-radius: 50%;
    height: 20vh;
    width: 20vh;
    object-fit: cover,
}

.input {
    border: solid 0.5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 100%;
    transition-duration: 0.2s;
}

.input:disabled {
    background-color: #F0F0F5;
}

.button {
    /* border: solid 1px rgba(0, 0, 0, 0.5); */
    border-radius: 10px;
    background-color: transparent;
    transition-duration: 0.2s;
}

.button:hover {
    border-radius: 10px;
    border-right: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.5);
    border-left: 2px solid rgba(0, 0, 0, 0.5);
}

.stats {
    border: solid 1px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-weight: 200;
    margin-top: 10vh;
}

.stats_container {
    padding: 20px 30px;
}

.back_button {
    margin-left: 30px;
    font-size: 24px;
    background-color: white;
    font-weight: 700;
    padding: 2px 30px;
    border-radius: 10px;
    border: 1px solid #080823;
    color: #080823;
    transition-duration: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.back_button:hover {
    background-color: #080823;
    color: white;
}

.modal_background {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    opacity: 100%;
    background-color: white;
    padding: 20px 40px;
}

.modal_input {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    transition-duration: 0.2s;
    margin-top: 2px;
    padding: 4px 4px;
}

.modal_input:focus {
    border: 1px solid blue;
} 

.verify_button {
    background-color:  rgb(59 130 246);
    transition-duration: 0.2s;
    border-radius: 10px;
    padding: 8px 24px;
    color: white;
    flex: 1;
}

.verify_button:hover {
    background-color: rgb(37 99 235);
}

.cancel_button {
    border-radius: 10px;
    padding: 8px 24px;
    transition-duration: 0.2s;
    margin-left: 5px;
    background-color: rgb(209 213 219);
}

.cancel_button:hover {
    background-color: rgb(156 163 175);
}

  .info-section {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #F0F0F5;
    border-radius: 8px;
  }
  
  .info-label {
    font-size: 14px;
    color: #1B263B;
    margin-bottom: 5px;
  }
  .video-container {
    display: flex;
    justify-content: center;
    padding-top: 120px;
    /* padding-top: 144px; Optional: Add some space above the video */
  }
  .info-value {
    font-size: 16px;
    color: #1B263B;
    font-weight: 500;
  }
  
  .edit-button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
    background-color: #007EA7;
    color: #F0F0F5;
    margin-top: 10px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancel-button {
    background-color: #ccc;
  }