.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
 
}
.user-profile-container {
  background-color: #F6F5F5;
}
.imagee{
  width: 150px;
  height: 150px;
  border-radius: 50%; /* Use kebab-case in CSS files */
  object-fit: cover;
  border: 2px solid #ccc;
}
.profile-title {
  color: #080823;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
:root{
  background-color: #F6F5F5;
}
.profile-card {
  background-color: #F6F5F5;
  min-height: 400px;

  padding: 24px;
}

.profile-content {
  position: relative;
}

.button-container {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: 8px;
}

.action-button {
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #f8f9fa;
}

.profile-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #e2e8f0;
  margin-bottom: 16px;
}

.profile-name {
  color: #080823;
  font-family: "Roboto", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.profile-username {
  color: #080823;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.description-container {
  width: 100%;
  max-width: 600px;
  min-height: 100px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px;
  color: #64748b;
  background-color: white;
  cursor: text;
}