.section {
    background-color: #0A0B1C;
    padding: 5rem 1rem;
  }
  
  .container {
    max-width: 60rem;
    margin: 0 auto;
  }
  
  .title {
    font-size: 3rem;
    font-weight: 700;
    color: white;
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .featuresList {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  
  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .featureHeader {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .featureTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
  }
  
  .featureDescription {
    color: #94a3b8;
    max-width: 100%;
    line-height: 1.6;
    font-size: 1.125rem;
  }
  
  /* Icon colors */

  .iconSecure {
    color: #fbbf24;
    width: 3rem;
    height: 3rem;
  }
  
  .iconRewards {
    color: #4ade80;
    width: 3rem;
    height: 3rem;
  }
  
  .iconPurchasing {
    color: #60a5fa;
    width: 3rem;
    height: 3rem;
  }
  
  .iconBeginner {
    color: #fde68a;
    width: 3rem;
    height: 3rem;
  }
  
  .iconSimplicity {
    color: #c084fc;
    width: 3rem;
    height: 3rem;
  }
  
  .iconTransparency {
    color: #22d3ee;
    width: 3rem;
    height: 3rem;
  }
  
  @media (max-width: 640px) {
    .title {
      font-size: 1.875rem;
      padding: 0 1rem;
    }
  
    .featureDescription {
      padding: 0 1rem;
    }
  }
  
  